<template>
    <div class="login-wrap">
        <div class="ms-login">
			
            <div class="ms-title">
				<el-image :src="image.logo"></el-image>
			</div>
			
            <el-form :model="param" :rules="rules" ref="login" class="ms-content">
                
				<el-form-item prop="phone">
                    <el-input class="login-input" clearable prefix-icon="User"
					v-model="param.phone" placeholder="请输入账号">
                        <!-- <template #prepend>
                            <el-button icon="Avatar"></el-button>
                        </template> -->
                    </el-input>
                </el-form-item>
				
                <el-form-item prop="password">
                    <el-input
					    show-password
                        type="password"
                        placeholder="请输入密码"
						class="login-input"
                        v-model="param.password"
						prefix-icon="Lock"
                        @keyup.enter="onKeyEnter()">
						
                        <!-- <template #prepend>
                            <el-button icon="Search"></el-button>
                        </template> -->
                    </el-input>
                </el-form-item>
				
				<el-form-item prop="password2" v-if="login_status==1">
				    <el-input
					    show-password
				        type="password"
				        placeholder="请再次输入密码"
						class="login-input"
				        v-model="param.password2"
						prefix-icon="Lock">
				        <!-- <template #prepend>
				            <el-button icon="Search"></el-button>
				        </template> -->
				    </el-input>
				</el-form-item>
				
                <div class="login-btn">
                    <div class="login-btn1" @click="onLogin()">
						{{login_status==0?'登录':'注册'}}
					</div>
					<div class="login-btn2" @click="onRegister()">
						{{login_status==0?'没有账号？去注册':'已有账号，去登录'}}
					</div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import {Login,Registe} from "../api/index";

export default {
    data() {
        return {
			
			image:{
			    logo:require("../assets/img/logo2.png")	
			},
			
            param: {
                phone: "",
                password: "",
				password2:"", //二次确认
            },
            rules: {
                phone: [
                    { required: true, message: "请输入账号", trigger: "blur" }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ],
				password2: [
				    { required: true, message: "请再次输入密码", trigger: "blur" }
				]
            },
			
			login_status:0, //0登录 1注册
        };
    },
	
    created() {
		this.login_status = 0;
        this.$store.commit("clearTags");
    },
	
    methods: {
		
        onLogin() {
			
			if(this.login_status==0){
				this.$refs.login.validate(valid => {
					
				    if (valid) {
						Login(this.param).then(res => {
							if (res.code != 0){
								this.$message({
									type:"error",
									message:res.msg
								});
							}else{
								console.log(res)
								localStorage.setItem("tt_username", this.param.phone);
								localStorage.setItem("token", res.data.token);
								localStorage.setItem("create_time", res.data.create_time);
								localStorage.setItem("commission", res.data.commission);
								localStorage.setItem("id", res.data.id);
								localStorage.setItem("invitation_code", res.data.invitation_code);
								localStorage.setItem("profit", res.data.profit);
								localStorage.setItem("amount", res.data.profit.amount);
								
								this.$router.push("/home");
								// this.$message({
								// 	type:"success",
								// 	message:"已登录"
								// });				
							}
						})
						
				    }
				});
			}else{
				// 去注册
				this.$refs.login.validate(valid => {
				    if (valid) {
						var invite = localStorage.getItem("invite");
						if(invite){
							this.param.code = invite
						}
						console.log(this.param)
						Registe(this.param).then(res => {
							console.log(res)
							if(res.code!=0){
								this.$message({
									type:"error",
									message:res.msg
								});
							}else{
								this.$message({
									type:"success",
									message:"注册成功,去登录"
								});
								this.login_status = 0
							}
							// localStorage.setItem("tt_username", this.param.username);
							// localStorage.setItem("tt_token", "eyxxxxxxxxxxxxxxxx.xxxxxxxxx.xxxxxxxxxx");
							// this.$router.push("/home");
							// //绑定邀请码
						})
				    }
				});
			}
        },
		
		onKeyEnter(){
			if(!this.login_status){
				return;
			}
			this.onLogin();
		},
		
		onRegister(){
			this.login_status = 1 - this.login_status;
		}
    }
};
</script>

<style>
	
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
	background-color: #fafafa;
}

.login-input{
	font-size: 12px;
	border-color: #34b785;
}

.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #3d3d3d;
	font-weight: 500;
	text-transform: uppercase;
    /* border-bottom: 1px solid #ddd; */
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    /* background: rgba(6, 77, 133, 0.4); */
	background-color: #fafafa;
    overflow: hidden;
}
.ms-content {
    /* padding: 120px 120px; */
}
.login-btn {
    display: flex;
	flex-direction: column;
}

.login-btn1{
	background-color: #2bb885;
	color:#fff;
	font-weight: bold;
	font-size: 20px;
	transition: background-color 0.5s;
	cursor: pointer;
	width: 100%;
	height: 60px;
	border-radius: 10px 10px 0px 0px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-btn1:hover{
	background-color: #45c794;
}

.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}

/*修改内置组件样式*/
.el-input__wrapper{
	padding: 0px;
	border-radius: 16px;
	width: 100%;
}

.login-btn2{
	background-color: #fff;
	color:#2bb885;
	transition: background-color 0.5s;
	cursor: pointer;
	width: calc(100% - 4px);
	height: 50px;
	border-radius: 0px 0px 10px 10px;
	border: 2px solid #2bb885;
	font-weight: bold;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-btn2:hover{
	background-color: #45c794;
	color: #fff;
}

</style>